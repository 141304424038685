<template>
  <div>
    Page not found
  </div>
</template>

<script>
export default {
  name: 'admin-games',
};
</script>

<style scoped lang="scss">
</style>
